import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'
import ModalIframe from '../components/Common/ModalIframe'

import YoutubePlayIcon from '../svg/youtube-play-icon'

import * as s from '../pages/news.module.scss'
import { Button } from '../components/Common/Button'
import { useTranslations } from '../hooks/use-translations'
import List from '../components/Common/List'

const DecemberDigest2024 = () => {
  const { getPath } = useTranslations()
  const [modalVideoData, setModalVideoData] = React.useState<{
    title: string
    src: string
  }>()

  const images = useStaticQuery(imagesQuery)

  return (
    <>
      <SEO
        title="Digest of the December updates on SIGNAX products"
        description="SIGNAX December 2024 Digest of updates and product materials, including SIGNAX DOCS CDE, INSPECTION - remote construction control service, DASHBOARD project management software and TOOLS solution for BIM management automation."
      />
      <NewsSectionBlock date="December 27, 2024">
        <Typography variant="h1" color="blue">
          December Digest SIGNAX
        </Typography>
        <Typography variant="h3">General</Typography>
        <Typography variant="body1">
          As we wrap up 2024, we’re excited to share highlights from an eventful
          December! This month, we introduced new features across SIGNAX
          modules, launched a free version of TOOLS, and celebrated client
          success stories. SIGNAX continues to develop cutting-edge solutions,
          ensuring your projects run smoothly and efficiently. Let’s take a
          closer look at what’s new and how we’ve supported our clients this
          month.
        </Typography>
        <Typography variant="body1">
          This month, we released two new videos focusing on implementation of
          SIGNAX solutions in different roles in construction projects.
        </Typography>
        <Typography variant="body1">
          The first video demonstrates how SIGNAX transforms QA/QC processes in
          construction projects by enabling seamless inspections, managing
          deviations, and generating actionable insights for project teams.
        </Typography>
        <div
          className={s.youtubeVideo}
          onClick={() =>
            setModalVideoData({
              title: `Track Quality of construction project with SIGNAX`,
              src: 'https://youtube.com/embed/PkP6t_JVR2s?si=LEQ9_xrQhXuyC358',
            })
          }
        >
          <GatsbyImage
            image={images.videoPreview1.childImageSharp.gatsbyImageData}
            alt="Track Quality of construction project with SIGNAX"
          />
          <YoutubePlayIcon />
        </div>
        <Typography variant="body1">
          The second video focuses on cost management, highlighting how SIGNAX
          simplifies budgeting and integrates with leading software for
          end-to-end project control. These videos offer a glimpse into how
          SIGNAX drives innovation across the construction industry.
        </Typography>
        <div
          className={s.youtubeVideo}
          onClick={() =>
            setModalVideoData({
              title: `SIGNAX. Manage costs in construction`,
              src: 'https://youtube.com/embed/6YpelVGgK1k?si=-muJxX9MLVoguf3x',
            })
          }
        >
          <GatsbyImage
            image={images.videoPreview2.childImageSharp.gatsbyImageData}
            alt="SIGNAX. Manage costs in construction"
          />
          <YoutubePlayIcon />
        </div>
        <Typography variant="h3">Customer success story</Typography>
        <Typography variant="body1">
          This month, we highlighted our collaboration with Radius Group in
          their logistics facility project in Dubai Investments Park. By
          leveraging drone surveys, photogrammetry, 360° ground inspections, and
          advanced software solutions, we delivered precise data and actionable
          insights.
        </Typography>
        <Typography variant="body1">Key achievements included:</Typography>
        <List>
          <li>
            Streamlined site preparation, mobilization and accelerated
            contractor mobilization.
          </li>
          <li>
            Enhanced stakeholder engagement with professional visuals and
            reports.
          </li>
          <li>Improved environmental planning and landscaping approvals.</li>
        </List>
        <Typography variant="body1">
          From precise site data collection to visually compelling
          presentations, this success story highlights how SIGNAX’ services and
          software transform raw data into actionable insights.
        </Typography>
        <Typography variant="body1">
          Click the button below to dive into the full article:
        </Typography>
        <Button
          className="mx-auto"
          size="small"
          onClick={() => navigate(getPath('/from-ground-to-cloud/'))}
        >
          Open Article
        </Button>
        <Typography variant="h3">DOCS</Typography>
        <Typography variant="body1">
          SIGNAX DOCS continues to lead the way in simplifying{' '}
          <span style={{ fontWeight: 500 }}>project workflows.</span> This
          month, we released a new function to create projects based on
          templates or existing projects, streamlining the{' '}
          <span style={{ fontWeight: 500 }}>project setup</span> process. Key
          elements such as folders, access rights, and reviews are automatically
          copied, ensuring consistency and efficiency. Users can now set up
          participants and adjust roles and permissions with ease when opening a
          project for the first time.
        </Typography>
        <Typography variant="body1">
          Additionally, new updates enhance{' '}
          <span style={{ fontWeight: 500 }}>document management</span> with
          features like version preservation when reverting files, an Issue
          Registry that links to file versions, and advanced{' '}
          <span style={{ fontWeight: 500 }}>model comparison</span> capabilities
          for comparing different files entirely. With these innovations, SIGNAX
          DOCS is making project management faster, more accurate, and more
          efficient.
        </Typography>
        <div
          className={s.youtubeVideo}
          onClick={() =>
            setModalVideoData({
              title: `SIGNAX DOCS. Schedules, Reviews and Notifications`,
              src: 'https://www.youtube.com/embed/VW8DM-Ouz2s?si=VLv8o_o6bbTjhhXJ',
            })
          }
        >
          <GatsbyImage
            image={images.videoPreview3.childImageSharp.gatsbyImageData}
            alt="SIGNAX DOCS. Schedules, Reviews and Notifications"
          />
          <YoutubePlayIcon />
        </div>
        <Typography variant="h3">TOOLS</Typography>
        <Typography variant="body1">
          SIGNAX TOOLS made waves this month with the release of its{' '}
          <span style={{ fontWeight: 500 }}>FREE version</span> for Revit and
          Navisworks, now available on the Autodesk Store. This solution
          empowers users with tools for effortless scope calculation, custom
          properties, and construction progress documenting, elevating BIM
          workflows. Users looking for the full power of SIGNAX TOOLS can
          upgrade to access features like server processing, model validation,
          and scope tracking. Start automating your BIM workflow today to save
          time and boost productivity.
        </Typography>
        <div className={s.imageWrap}>
          <GatsbyImage
            image={images.image1.childImageSharp.gatsbyImageData}
            alt="SIGNAX TOOLS now has a FREE version"
          />
        </div>
        <Typography variant="h3">HOLIDAY WISHES</Typography>
        <Typography variant="body1">
          As we approach the festive season, SIGNAX wishes you a Merry Christmas
          and a Happy New Year! We’re proud to support you in achieving your
          project goals and remain available throughout the holidays to provide
          assistance whenever needed.
        </Typography>
        <Typography variant="body1">
          Thank you for being a part of our journey in 2024. Let’s make 2025
          another year of innovation, success, and collaboration!
        </Typography>
        <div className={s.imageWrap}>
          <GatsbyImage
            image={images.image2.childImageSharp.gatsbyImageData}
            alt="Happy New Year from SIGNAX!"
          />
        </div>
      </NewsSectionBlock>

      {modalVideoData && (
        <ModalIframe
          title={modalVideoData.title}
          src={modalVideoData.src}
          srcTitle={modalVideoData.title}
          onClose={() => setModalVideoData(undefined)}
        />
      )}
    </>
  )
}

const imagesQuery = graphql`
  query {
    videoPreview1: file(
      relativePath: { eq: "news/december-digest-2024/video-preview-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    videoPreview2: file(
      relativePath: { eq: "news/december-digest-2024/video-preview-2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    videoPreview3: file(
      relativePath: { eq: "news/december-digest-2024/video-preview-3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    image1: file(
      relativePath: { eq: "news/december-digest-2024/image-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    image2: file(
      relativePath: { eq: "news/december-digest-2024/image-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
  }
`

export default DecemberDigest2024
