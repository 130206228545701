import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import cn from 'classnames'

import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'
import BannerSection from '../components/BannerSection'
import List from '../components/Common/List'
import Carousel from '../components/Carousel'
import { TImageStaticQuery } from '../types/pages'

const FromGroundToCloud = () => {
  const carouselData0 = [
    {
      imageId: 'carouselData0image1',
      alt: 'Project-overview-1',
    },
    {
      imageId: 'carouselData0image2',
      alt: 'Project-overview-2',
    },
    {
      imageId: 'carouselData0image3',
      alt: 'Project-overview-3',
    },
    {
      imageId: 'carouselData0image4',
      alt: 'Project-overview-4',
    },
  ]

  const carouselData1 = [
    {
      imageId: 'carouselData1image1',
      alt: 'Preparation-phase-1',
    },
    {
      imageId: 'carouselData1image2',
      alt: 'Preparation-phase-2',
    },
  ]

  const carouselData2 = [
    {
      imageId: 'carouselData2image1',
      alt: 'Field-operations-1',
    },
    {
      imageId: 'carouselData2image2',
      alt: 'Field-operations-2',
    },
    {
      imageId: 'carouselData2image3',
      alt: 'Field-operations-3',
    },
    {
      imageId: 'carouselData2image4',
      alt: 'Field-operations-4',
    },
  ]

  const carouselData3 = [
    {
      imageId: 'carouselData3image1',
      alt: 'Material-preparation-1',
    },
    {
      imageId: 'carouselData3image2',
      alt: 'Material-preparation-2',
    },
  ]

  const carouselData4 = [
    {
      imageId: 'carouselData4image1',
      alt: 'Use-cases-and-benefits-1',
    },
    {
      imageId: 'carouselData4image2',
      alt: 'Use-cases-and-benefits-2',
    },
  ]

  const images = useStaticQuery(imagesQuery) as TImageStaticQuery

  return (
    <>
      <SEO
        title="From Ground to Cloud: Turn Data into Action"
        description="Revolutionizing construction workflows, Radius Group’s partnership with SIGNAX showcases the power of drone surveys and software solutions to address engineering tasks."
      />
      <BannerSection
        imageDesktop={images.banner.childImageSharp.gatsbyImageData}
        title={`From Ground to Cloud:\n Turn Data into Action`}
        subTitle={`Optimizing construction workflows, SIGNAX showcases the power of drone surveys and software solutions to handle Radius Group’s engineering tasks`}
        titleSize={60}
        imgStyle={{ height: '467px' }}
      />
      <NewsSectionBlock>
        <Typography variant="h3">Project overview</Typography>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          Radius Group City Logistics @DIP II, Dubai, UAE
        </Typography>
        <Typography variant="body1">
          City Logistics @DIP II is a 32,900 sq.m. (GFA) temperature-controlled
          logistics facility located on a 5.2-hectare site at Dubai Investments
          Park. The facility consists of four independent blocks, each with its
          own fitted-out office entrance groups. Developed to meet Class A
          institutional standards, this project is designed to serve as a
          high-quality logistics hub in the UAE.
        </Typography>
        <Carousel
          className="mt-4"
          images={images}
          carouselData={carouselData0}
          centeredSlides={true}
        />
        <Typography variant="h3">Introduction</Typography>
        <Typography variant="body1">
          SIGNAX recently took part in an innovative project by Radius Group,
          utilizing drone technology, ground inspections, advanced
          photogrammetry, and cutting-edge software to optimize the client’s
          operations. The project aimed to address the following key objectives:
        </Typography>
        <List>
          <li>Enhance environmental planning</li>
          <li>Connection of engineering systems</li>
          <li>
            Identifying and selecting various site masterplan configurations
          </li>
          <li>Calculating earthwork volumes</li>
          <li>Construction management plan (CMP) development</li>
          <li>Preparing documentation and reports for regulatory approvals</li>
          <li>Deliver compelling visuals for stakeholders</li>
        </List>
        <Typography variant="body1">
          Through careful execution and data processing, we helped the client
          make informed decisions, streamline operations, and improve their
          project’s visibility in the market.
        </Typography>
        <Typography variant="h3">1. Preparation Phase</Typography>
        <Typography variant="body1">
          The project began with a comprehensive analysis of the site’s
          condition and the goals at hand:
        </Typography>
        <List>
          <li>
            <span style={{ fontWeight: 500 }}>Goal</span>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              The primary objective was to improve site preparation and
              stakeholder coordination by providing precise data and actionable
              insights. This included identifying opportunities to repurpose
              leftover concrete slabs and aligning landscaping plans with both
              environmental guidelines and project requirements.
            </Typography>
          </li>
          <li>
            <span style={{ fontWeight: 500 }}>Solution</span>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              SIGNAX initiated preparations to address these needs by planning
              and performing drone photogrammetry works, coordinating with all
              relevant government authorities, ensuring clear communication
              channels, and setting objectives for both site preparation and
              mobilization and planning green spaces.
            </Typography>
          </li>
        </List>
        <Carousel
          className="mt-4"
          images={images}
          carouselData={carouselData1}
          centeredSlides={true}
        />
        <Typography variant="body1">Key actions included:</Typography>
        <List>
          <li>
            Selecting drones and software capable of creating precise data for
            both topographic analysis and vegetation planning
          </li>
          <li>
            Developing a clear schedule for drone flights and ground inspections
            in alignment with construction milestones
          </li>
          <li>
            Obtaining all the required approvals and NOC from government and
            local authorities
          </li>
        </List>
        <Typography variant="h3">2. Field Operations</Typography>
        <Typography variant="body1">
          Field works in the construction site were focused on aerial and
          ground-level data collection:
        </Typography>
        <List>
          <li>
            <span style={{ fontWeight: 500 }}>Goal</span>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              The objective was to achieve complete and precise site data
              collection, including consistent and accurate elevation
              measurements, particularly in hard-to-access areas, to support
              effective planning and decision-making.
            </Typography>
          </li>
          <li>
            <span style={{ fontWeight: 500 }}>Solution</span>
            <ol className={cn(s.textLists, s.decimal)}>
              <li style={{ paddingLeft: 0 }}>
                <Typography
                  variant="body1"
                  style={{ textIndent: 0, marginBottom: 0 }}
                >
                  {` `}Aerial. SIGNAX deployed drones for two aerial surveys in
                  March and November 2024, capturing high-resolution imagery and
                  elevation data using photogrammetry. For better coordination -
                  SIGNAX used ground control points that were coordinated using
                  best-in-class survey equipment to achieve centimeter accuracy
                  of point cloud and masterplan.
                </Typography>
              </li>
              <li style={{ paddingLeft: 0 }}>
                <Typography
                  variant="body1"
                  style={{ textIndent: 0, marginTop: 0 }}
                >
                  {` `}Ground. Simultaneously, ground inspections during the
                  same periods provided detailed insights into specific zones of
                  interest, enabling comparison of panoramic photos taken on the
                  same spot from different dates.
                </Typography>
              </li>
            </ol>
          </li>
        </List>
        <Typography variant="body1">Highlights of our approach:</Typography>
        <List>
          <li>
            Drone flights were planned to cover the entire site while focusing
            on zones and areas selected for landscaping
          </li>
          <li>
            Photogrammetric data was processed to generate accurate point
            clouds, ensuring no detail was overlooked
          </li>
          <li>
            Ground inspections validated aerial findings and provided an extra
            layer of detail for actionable recommendations
          </li>
        </List>
        <Carousel
          className="mt-4"
          images={images}
          carouselData={carouselData2}
          centeredSlides={true}
        />
        <Typography variant="h3">3. Material Preparation</Typography>
        <Typography variant="body1">
          Transforming raw data into actionable insights was critical to help
          client achieve their goals:
        </Typography>
        <List>
          <li>
            <span style={{ fontWeight: 500 }}>Goal</span>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              The objective was to convert raw data into clear, actionable
              visuals that would support the client in effective planning and
              decision-making. Additionally, there was a need to create
              informative and engaging visual materials to communicate progress
              and insights to stakeholders worldwide.
            </Typography>
          </li>
          <li>
            <span style={{ fontWeight: 500 }}>Solution</span>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              We processed the photogrammetry data and created a highly detailed
              point cloud using specialized software. Ground control points were
              carefully coordinated, combining data from the ground with precise
              coordinates obtained through advanced survey equipment.
            </Typography>
          </li>
        </List>
        <Typography variant="body1">
          For seamless collaboration and accessibility, the point cloud was
          uploaded to SIGNAX DOCS, enabling stakeholders to view it from any
          device. Additionally, ground photos were uploaded to SIGNAX
          INSPECTION, ensuring easy comparison and visualization of the site’s
          details.
        </Typography>
        <Typography variant="body1">Deliverables included:</Typography>
        <ol className={cn(s.textLists, s.decimal)}>
          <li style={{ paddingLeft: 0 }}>
            <Typography
              variant="body1"
              style={{ textIndent: 0, marginBottom: 0 }}
            >
              {` `}Detailed Point Cloud. High-resolution point cloud showing
              construction site area and landscaping.
            </Typography>
          </li>
          <li style={{ paddingLeft: 0 }}>
            <Typography variant="body1" style={{ textIndent: 0, marginTop: 0 }}>
              {` `}Marketing-Ready Photos. Professionally performed high-res
              photos from drone showcasing progress to investors and
              stakeholders.
            </Typography>
          </li>
        </ol>
        <Carousel
          className="mt-4"
          images={images}
          carouselData={carouselData3}
          centeredSlides={true}
        />
        <Typography variant="h3">4. Use Cases and Benefits</Typography>
        <Typography variant="body1">
          The results of the project directly impacted the client goal and
          stakeholder engagement:
        </Typography>
        <List>
          <li>
            <span style={{ fontWeight: 500 }}>Goal</span>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              The objective was to streamline contractor mobilization and
              expedite landscaping approvals by providing precise data and
              actionable insights. Additionally, there was a need to enhance
              project presentations for stakeholders and potential investors
              with engaging and informative visuals.
            </Typography>
          </li>
          <li>
            <span style={{ fontWeight: 500 }}>Solution</span>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              SIGNAX’s services and solutions enabled:
            </Typography>
            <ol className={cn(s.textLists, s.decimal)}>
              <li style={{ paddingLeft: 0 }}>
                <Typography
                  variant="body1"
                  style={{ textIndent: 0, marginBottom: 0 }}
                >
                  {` `}Site Preparation Acceleration. The photogrammetric
                  analysis pinpointed exact locations and scopes of work,
                  allowing contractors to mobilize quickly and efficiently.
                </Typography>
              </li>
              <li style={{ paddingLeft: 0 }}>
                <Typography
                  variant="body1"
                  style={{ textIndent: 0, marginTop: 0 }}
                >
                  {` `}Marketing Impact. Engaging visuals and reports improved
                  the client’s ability to present their project to potential
                  investors and stakeholders.
                </Typography>
              </li>
            </ol>
          </li>
        </List>
        <Typography variant="body1">
          The project highlighted SIGNAX’s ability to transform complex data
          into actionable insights, empowering the client to make informed
          decisions and maintain project timelines.
        </Typography>
        <Carousel
          className="mt-4"
          images={images}
          carouselData={carouselData4}
          centeredSlides={true}
        />
        <Typography variant="h3">Conclusion</Typography>
        <Typography variant="body1">
          In conclusion, this project demonstrated how SIGNAX’s services,
          including 360-degree photos, photogrammetry, and cloud-based
          construction management tools like INSPECTION and DOCS, effectively
          transform raw data into actionable insights. These solutions bridge
          the gap between complex site data and practical decision-making, and
          help stakeholders visualize progress and achieve their goals.
        </Typography>
        <Typography variant="body1">
          SIGNAX is committed to driving innovation in construction and site
          management, offering our clients services and tools they need to
          achieve success.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

const imagesQuery = graphql`
  query {
    banner: file(relativePath: { eq: "news/from-ground-to-cloud/banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
    carouselData0image1: file(
      relativePath: { eq: "news/from-ground-to-cloud/slider0-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 782, placeholder: BLURRED)
      }
    }
    carouselData0image2: file(
      relativePath: { eq: "news/from-ground-to-cloud/slider0-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 782, placeholder: BLURRED)
      }
    }
    carouselData0image3: file(
      relativePath: { eq: "news/from-ground-to-cloud/slider0-3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 782, placeholder: BLURRED)
      }
    }
    carouselData0image4: file(
      relativePath: { eq: "news/from-ground-to-cloud/slider0-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 782, placeholder: BLURRED)
      }
    }
    carouselData1image1: file(
      relativePath: { eq: "news/from-ground-to-cloud/slider1-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 782, placeholder: BLURRED)
      }
    }
    carouselData1image2: file(
      relativePath: { eq: "news/from-ground-to-cloud/slider1-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 782, placeholder: BLURRED)
      }
    }
    carouselData2image1: file(
      relativePath: { eq: "news/from-ground-to-cloud/slider2-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 782, placeholder: BLURRED)
      }
    }
    carouselData2image2: file(
      relativePath: { eq: "news/from-ground-to-cloud/slider2-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 782, placeholder: BLURRED)
      }
    }
    carouselData2image3: file(
      relativePath: { eq: "news/from-ground-to-cloud/slider2-3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 782, placeholder: BLURRED)
      }
    }
    carouselData2image4: file(
      relativePath: { eq: "news/from-ground-to-cloud/slider2-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 782, placeholder: BLURRED)
      }
    }
    carouselData3image1: file(
      relativePath: { eq: "news/from-ground-to-cloud/slider3-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 782, placeholder: BLURRED)
      }
    }
    carouselData3image2: file(
      relativePath: { eq: "news/from-ground-to-cloud/slider3-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 782, placeholder: BLURRED)
      }
    }
    carouselData4image1: file(
      relativePath: { eq: "news/from-ground-to-cloud/slider4-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 782, placeholder: BLURRED)
      }
    }
    carouselData4image2: file(
      relativePath: { eq: "news/from-ground-to-cloud/slider4-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 782, placeholder: BLURRED)
      }
    }
  }
`

export default FromGroundToCloud
