import React from 'react'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import ArrowButton from '../../components/Common/ArrowButton'
import cn from 'classnames'
import './Carousel.styles.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import { TImageStaticQuery } from '../../types/pages'

interface IProps {
  images: TImageStaticQuery
  carouselData: { imageId: string; alt: string }[]
  className?: string
  autoHeight?: boolean
  centeredSlides?: boolean
  slidesPerView?: number | 'auto'
}

const Carousel: React.FC<IProps> = ({
  images,
  className,
  autoHeight,
  children,
  carouselData,
  centeredSlides,
  slidesPerView,
}) => {
  const swiperRef = React.useRef<SwiperRef>(null)
  const [disabledNavigationPrev, setDisabledNavigationPrev] =
    React.useState(true)
  const [disabledNavigationNext, setDisabledNavigationNext] =
    React.useState(false)
  const [activeSlideIdx, setActiveSlideIdx] = React.useState(0)
  return (
    <div className={cn('flex items-center', className)}>
      <Swiper
        ref={swiperRef}
        freeMode={true}
        grabCursor={true}
        navigation={true}
        autoHeight={autoHeight}
        onSlideChange={swiper => {
          setDisabledNavigationPrev(swiper.isBeginning)
          setDisabledNavigationNext(swiper.isEnd)
          setActiveSlideIdx(swiper.activeIndex)
        }}
        pagination={{
          clickable: true,
          bulletClass: 'pagination',
          bulletActiveClass: 'active',
        }}
        modules={[Pagination]}
        centeredSlides={centeredSlides}
        slidesPerView={slidesPerView ?? 'auto'}
      >
        {carouselData.map((item, idx) => {
          const isActiveSlide = activeSlideIdx === idx
          return (
            <SwiperSlide key={idx} className="slide">
              <div
                className="flex flex-col items-center justify-center  mb-6"
                style={{
                  opacity: !isActiveSlide ? 0.2 : undefined,
                }}
              >
                <GatsbyImage
                  image={images[item.imageId].childImageSharp.gatsbyImageData}
                  alt={item.alt}
                />
              </div>
            </SwiperSlide>
          )
        })}
        {children}
        <ArrowButton
          onClick={() => swiperRef.current?.swiper.slidePrev()}
          disabled={disabledNavigationPrev}
          arrowType="left"
          className="btn left"
        />
        <ArrowButton
          arrowType="right"
          onClick={() => swiperRef.current?.swiper.slideNext()}
          disabled={disabledNavigationNext}
          className="btn right"
        />
      </Swiper>
    </div>
  )
}

export default Carousel
